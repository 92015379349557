import { MapDisplay, Options } from './map';

const defOptions: Options = {
    zoom: {
        min: 10,
        max: 16
    },
    maxBounds: [ 51.04, -4.18, 51.147, -3.94 ]
}

export function configure(mapId: string = 'mapid', option: Options = defOptions): MapDisplay {
    return new MapDisplay(mapId, option);
}