
    
/**
 * Determined the difference between set A and set B.
 * @param setA 
 * @param setB 
 */
export function difference<T>(setA: Set<T>, setB: Set<T>): Set<T> {
    let _difference: Set<T> = new Set(setA);
    setB.forEach(e => _difference.delete(e));
    return _difference
}

export function permute(rows: number, cols: number, callback: (row: number, col: number) => void) {
    const r: Array<{r:number, c: number}> = new Array((2*rows + 1)*(2*cols + 1));
    let total: number = (2*rows + 1)*(2*cols + 1);
    for(let v: number = 0; v < total; v++) {
        let col: number = v % (2*cols + 1);
        let row: number = Math.floor(v / (2*cols + 1));
        r.push({ r: row - rows, c: col - cols });
    }
    r.sort((a, b) => a.r*a.r + a.c*a.c - b.r*b.r - b.c*b.c)
    r.forEach((item) => callback(item.r, item.c));
}