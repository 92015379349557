import { Coordinate, Dimension, latlon2xy, xy2latLon, lat2y, lon2x } from './transform';
import { LRU } from './cache';
import { difference, permute } from './util';
import RedPin from './images/red.png';
import BluePin from './images/blue.png';
import LightPin from './images/light.png';
import GreenPin from './images/green.png';
import ArrowSouth from './images/south.png';
import ArrowNorth from './images/north.png';
import ArrowEast from './images/east.png';
import ArrowWest from './images/west.png';
import Plus from './images/plus.png';
import Minus from './images/minus.png';

export interface Options {
    zoom: { min: number, max: number },
    maxBounds: number[]
}

const TILE_SIZE: Dimension = { width: 250, height: 250 };

const DEBUG: boolean = true;

/** a 1x1 pixel image */
const Blank: string = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

enum ControlEventType { PAN = 0, ZOOM = 4 };

export class MapDisplay {

    private root: HTMLDivElement;
    private layers: Array<Layer>;
    private anchor: Coordinate; // xy map center coordinate (including fractions)
    private zoom: number; // zoom level (including fractions)
    private options: Options;

    private dimension: Dimension;

    private markers: Array<{ lat: number, lon: number, text: string, color: string }> = new Array();
    
    /**
     * Create a new map.
     * @param containerId the ID attribute of the div containing the map
     * @param options 
     */
    constructor(containerId: string = 'mapid', options: Options) {
        this.options = options;
        this.root = <HTMLDivElement>document.getElementById(containerId);
        this.root.style.setProperty('overflow', 'hidden');
        this.root.style.setProperty('position', 'relative');
        this.root.style.setProperty('zIndex', '0');
        this.root.addEventListener('control', (e: CustomEvent) => this.processControlEvent(e), true);
        window.addEventListener('resize', (e: Event) => this.init());
        this.layers = new Array(4);
        this.init();
        this.addMouseDragHandler(); // attached to root div
        this.addTouchDragHandler(); // attached to root div
    }

    protected init(): void {
        while(this.root.firstChild) {
            this.root.removeChild(this.root.lastChild); // clear root division
        }
        this.dimension = { width: this.root.clientWidth, height: Math.max(300, this.root.clientHeight) };
        this.layers[0] = new MapLayer(this.root, this.dimension);
        this.layers[1] = new ControlLayer(this.root, this.dimension);
        this.layers[2] = new AttributionLayer(this.root, this.dimension, 'Weirholme Software (using OSM data)');
        this.layers[3] = new MarkerLayer(this.root, this.dimension);
        this.layers.forEach((layer: Layer) => layer.setVisible(true));
        this.markers.forEach(marker => (<MarkerLayer>this.layers[3]).addMarker(marker));
        if(this.anchor && this.zoom) { // only render if the anchor and zoom properties are known
            this.root.dispatchEvent(new CustomEvent('map', { detail: { x: this.anchor.x, y: this.anchor.y, zoom: this.zoom }}));
        }
    }

    private addMouseDragHandler(): void {
        let drag: boolean = false;
        let origin: Coordinate = null;
        this.root.addEventListener('mousedown', (e: MouseEvent) => { drag = true; origin = new Coordinate(e.pageX, e.pageY); document.body.style.cursor = 'move'; });
        const exitDrag: (e: MouseEvent) => void = function() { drag = false; document.body.style.cursor = 'default'; };
        this.root.addEventListener('mouseup', exitDrag);
        this.root.addEventListener('mouseleave', exitDrag);
        this.root.addEventListener('mousemove', (e: MouseEvent) => {
            if(drag) {
                const offset: Coordinate = new Coordinate(origin.x - e.pageX, origin.y - e.pageY);
                origin = new Coordinate(e.pageX, e.pageY);
                this.root.dispatchEvent(new CustomEvent('control', { detail: { type: ControlEventType.PAN, dx: offset.x/TILE_SIZE.width, dy: offset.y/TILE_SIZE.height, dz: 0 } }));
            }
        });
    }

    private addTouchDragHandler(): void {
        let drag: boolean = false;
        let origin: Coordinate = null;
        this.root.addEventListener('touchstart', (e: TouchEvent) => { console.log(e); drag = true; origin = new Coordinate(e.changedTouches.item(0).pageX, e.changedTouches.item(0).pageY); document.body.style.cursor = 'move'; });
        const exitDrag: (e: MouseEvent) => void = function() { drag = false; document.body.style.cursor = 'default'; };
        this.root.addEventListener('touchend', exitDrag);
        this.root.addEventListener('touchcancel', exitDrag);
        this.root.addEventListener('touchmove', (e: TouchEvent) => {
            if(drag) {
                // pick the first touch point
                const offset: Coordinate = new Coordinate(origin.x - e.changedTouches.item(0).pageX, origin.y - e.changedTouches.item(0).pageY);
                origin = new Coordinate(e.changedTouches.item(0).pageX, e.changedTouches.item(0).pageY);
                this.root.dispatchEvent(new CustomEvent('control', { detail: { type: ControlEventType.PAN, dx: offset.x/TILE_SIZE.width, dy: offset.y/TILE_SIZE.height, dz: 0 } }));
            } 
        });
    }

    private processControlEvent(event: CustomEvent): void {
        const scale: number = 1.0 - (Math.ceil(this.zoom) - this.zoom)/2.0;
        const yMaxDisplay: number = this.dimension.height/TILE_SIZE.height/2*scale;
        const xMaxDisplay: number =  this.dimension.width/TILE_SIZE.width/2*scale;

        let xMinBound: number = lon2x(this.options.maxBounds[1], Math.ceil(this.zoom));
        let xMaxBound: number = lon2x(this.options.maxBounds[3], Math.ceil(this.zoom));
        // y is reversed latitude in tile space 
        let yMinBound: number = lat2y(this.options.maxBounds[2], Math.ceil(this.zoom));
        let yMaxBound: number = lat2y(this.options.maxBounds[0], Math.ceil(this.zoom));
        
        let dx: number = event.detail.dx;
        let dy: number = event.detail.dy;

        const westTouch: boolean = this.anchor.x - xMaxDisplay <= xMinBound;
        const eastTouch: boolean = this.anchor.x + xMaxDisplay >= xMaxBound;
        const northTouch: boolean = this.anchor.y - yMaxDisplay <= yMinBound;
        const southTouch: boolean = this.anchor.y + yMaxDisplay >= yMaxBound;
    
        const control: ControlLayer = (<ControlLayer>this.layers[1]);

        control.setButtonState(0, !northTouch && !(northTouch && southTouch));
        control.setButtonState(1, !eastTouch && !(eastTouch && westTouch));
        control.setButtonState(2, !southTouch && !(northTouch && southTouch));
        control.setButtonState(3, !westTouch && !(eastTouch && westTouch));

        if(event.detail.type === ControlEventType.ZOOM) {
            let dz: number = event.detail.dz;
            const zoomMax: boolean = this.zoom >= this.options.zoom.max;
            const zoomMin: boolean = this.zoom <= this.options.zoom.min;
            control.setButtonState(4, !zoomMax); // zoom in
            control.setButtonState(5, !zoomMin && !(westTouch && northTouch && eastTouch && westTouch)); // zoom out
            if( !(dz < 0 && zoomMin) && !(dz > 0 && zoomMax)) {
                this.zoom = this.adjustZoom(dz);
            }
        } else if(event.detail.type == ControlEventType.PAN) {
            // limit movements
            if(eastTouch && westTouch) dx = 0;
            if(northTouch && southTouch) dy = 0;
            if(eastTouch && dx > 0) dx = 0;
            if(westTouch && dx < 0) dx = 0;
            if(northTouch && dy < 0) dy = 0;
            if(southTouch && dy > 0) dy = 0
            this.anchor = this.anchor.translate(dx, dy);
        }
        this.root.dispatchEvent(new CustomEvent('map', { detail: { x: this.anchor.x, y: this.anchor.y, zoom: this.zoom }}));
        event.stopPropagation();
    }

    /**
     * Fluent pattern
     * @param lat float latitude
     * @param lon float longitude
     * @param zoom float zoom level
     */
    public centerAt(lat: number, lon: number, zoom: number): MapDisplay {
        this.anchor = latlon2xy(lat, lon, Math.ceil(zoom));
        this.zoom = zoom;
        this.root.dispatchEvent(new CustomEvent('map', { detail: { x: this.anchor.x, y: this.anchor.y, zoom: this.zoom }}));
        return this;
    }

    /**
     * Fluent pattern
     * @param lat  float latitude
     * @param lon float longitude
     * @param text string marker text
     * @param color string marker color deault to 'blue'
     */
    public addMarker(lat: number, lon: number, text: string, color: string = 'blue'): MapDisplay {
        const marker: { lat: number, lon: number, text: string, color: string } = { lat: lat, lon: lon, text: text, color: color };
        const index: number = this.markers.findIndex( (marker) => marker.text === text);
        if(index < 0) {
            this.markers.push(marker); // new marker
        } else {
            this.markers[index].lat = marker.lat;
            this.markers[index].lon = marker.lon;
            this.markers[index].color = marker.color;
        }
        (<MarkerLayer>this.layers[3]).addMarker(marker);
        this.root.dispatchEvent(new CustomEvent('map', { detail: { x: this.anchor.x, y: this.anchor.y, zoom: this.zoom }}));
        return this;
    }

    private adjustZoom(dz: number): number {
        const latLon: Coordinate = xy2latLon(this.anchor.x, this.anchor.y, Math.ceil(this.zoom));
        this.anchor = latlon2xy(latLon.x, latLon.y, Math.ceil(this.zoom + dz));
        return this.zoom + dz;
    }

}

abstract class Layer {

    zIndex: string;
    root: HTMLDivElement;
    dimension: Dimension;

    constructor(root: HTMLDivElement, dimension: Dimension, zIndex: number = 0) {
        this.root = root;
        this.dimension = dimension;
        this.zIndex = zIndex.toFixed(0);
    }

    abstract setVisible(visible: boolean): void;

}

class MapLayer extends Layer {

    private rows: number;
    private cols: number;

    private lru: LRU<HTMLImageElement> ;
    private images: Set<HTMLImageElement>;

    constructor(root: HTMLDivElement, dimension: Dimension) {
        super(root, dimension, 0); // z-index is zero
        this.dimension = dimension;
        this.cols = Math.ceil(this.dimension.width/TILE_SIZE.width);
        this.rows = Math.ceil(this.dimension.height/TILE_SIZE.height);
        this.images = new Set();
        this.lru = new LRU(100);
        this.root.removeEventListener('map', null);
        this.root.addEventListener('map', (e: CustomEvent) => this.render(e));
    }

    private render(e: CustomEvent): void {
        const scale: number = 1.0 - (Math.ceil(e.detail.zoom) - e.detail.zoom)/2.0;
        const xIdx: number = Math.round(e.detail.x); // x find the nearest index
        const yIdx: number = Math.round(e.detail.y); // y find the nearest index
        const offset: Coordinate = new Coordinate(e.detail.x - xIdx, e.detail.y - yIdx);
        const images: Set<HTMLImageElement> = new Set();
        permute(this.rows, this.cols, (row: number, col: number) => {
            // do not load images located outside the view
            if(row * TILE_SIZE.height > this.dimension.height/2 || col*TILE_SIZE.width > this.dimension.width) return;
            const key: string = `${Math.ceil(e.detail.zoom)}/${xIdx + col}/${yIdx + row}`;
                let image: HTMLImageElement|null = this.lru.get(key);
                if(!image) {
                    image = new Image();
                    image.setAttribute('style', `position:absolute;z-index:${this.zIndex};`);
                    image.addEventListener('load', (e: Event) => this.lru.put(key, <HTMLImageElement>e.target));
                    image.addEventListener('error', (e: Event) => { (<HTMLImageElement>e.target).src = Blank });
                    image.src = `./map/${key}.png`;
                    image.draggable = false;
                    image.addEventListener('contextmenu', (e) => { e.preventDefault(); });
                }
                images.add(image);
                image.width = TILE_SIZE.width * scale;
                image.height = TILE_SIZE.height * scale;
                const ypos: number = this.dimension.height/2.0 + (row - offset.y) * TILE_SIZE.height * scale;
                const xpos: number = this.dimension.width/2.0 + (col - offset.x) * TILE_SIZE.width * scale;
                image.style.top = `${ypos}px`;
                image.style.left = `${xpos}px`;
                image.alt = DEBUG ? `(${xIdx + col},${yIdx + row})` : '';
            }
        );
        /* Add all new images to the image set, remove the old images which are not reused, append to the container */
        images.forEach(img => this.images.add(img));
        difference(this.images, images).forEach(img => {
            this.images.delete(img);
            this.root.removeChild(img);
        }); 
        this.images.forEach(img => this.root.appendChild(img));
    }

    setVisible(visible: boolean): void {
        // nothing to be done
    }
}

class ControlLayer extends Layer {

    private panElement: HTMLDivElement;
    private zoomElement: HTMLDivElement;
    private showPan: boolean;
    private showZoom: boolean;

    private buttons: HTMLButtonElement[] = new Array(6);

    constructor(root: HTMLDivElement, dimension: Dimension, showPan: boolean = true, showZoom: boolean = true) {
        super(root, dimension, 1);
        this.showPan = showPan;
        this.showZoom = showZoom;
        this.panElement = document.createElement('div');
        this.panElement.setAttribute('style',`width:75px;heigth:75px;position:absolute;right:5px;top:5px;z-index:${this.zIndex}`);
        // create buttons
        this.buttons[0] = ControlLayer.createButton(ArrowNorth, 25, 0);
        this.buttons[0].addEventListener('click', () => this.onMouseClick({ type: ControlEventType.PAN, dx: 0, dy: -0.25, dz: 0 }));
        this.buttons[2] = ControlLayer.createButton(ArrowSouth, 25, 50);
        this.buttons[2].addEventListener('click', () => this.onMouseClick({ type: ControlEventType.PAN, dx: 0, dy: +0.25, dz: 0 }));
        this.buttons[1] = ControlLayer.createButton(ArrowEast, 50, 25);
        this.buttons[1].addEventListener('click', () => this.onMouseClick({ type: ControlEventType.PAN, dx: +0.25, dy: 0, dz: 0 }));
        this.buttons[3] =ControlLayer.createButton(ArrowWest, 0, 25);
        this.buttons[3].addEventListener('click', () => this.onMouseClick({ type: ControlEventType.PAN, dx: -0.25, dy: 0, dz: 0 }));
        this.panElement.appendChild(this.buttons[0]);
        this.panElement.appendChild(this.buttons[1]);
        this.panElement.appendChild(this.buttons[2]);
        this.panElement.appendChild(this.buttons[3]);
        this.zoomElement = document.createElement('div');
        this.zoomElement.setAttribute('style',`width:25px;heigth:55px;position:absolute;right:5px;bottom:60px;z-index:${this.zIndex}`);
        this.buttons[4] = ControlLayer.createButton(Plus, 0, 0);
        this.buttons[5] = ControlLayer.createButton(Minus, 0, 30);
        this.zoomElement.appendChild(this.buttons[4]);
        this.buttons[4].addEventListener('click', () => this.onMouseClick({ type: ControlEventType.ZOOM, dx: 0, dy: 0, dz: +0.5 }));
        this.zoomElement.appendChild(this.buttons[5]);
        this.buttons[5].addEventListener('click', () => this.onMouseClick({ type: ControlEventType.ZOOM, dx: 0, dy: 0, dz: -0.5 }));
        // add to root
        this.root.appendChild(this.panElement);
        this.root.appendChild(this.zoomElement);
    }

    public setButtonState(index: number, state: boolean) {
        if(index < this.buttons.length) {
            this.buttons[index].disabled = !state;
        }
    }

    private static createButton(name: string, x: number, y: number): HTMLButtonElement {
        const button: HTMLButtonElement = document.createElement('button');
        const img: HTMLImageElement = document.createElement('img');
        img.width = 16;
        img.height = 16;
        img.src = name;
        img.draggable = false;
        img.alt = '';
        img.setAttribute('style', 'position:absolute;top:50%,left:50%;transform:translate(-50%,-50%);');
        button.setAttribute('style', `position:absolute;left:${x}px;top:${y}px;width:25px;height:25px;padding:0;`);
        button.appendChild(img);
        button.disabled = false;
        button.draggable = false;
        return button;
    }

    private onMouseClick(event: { type: ControlEventType, dx: number, dy: number, dz: number}) {
        this.root.dispatchEvent(new CustomEvent('control', { detail: { type: event.type, dx: event.dx, dy: event.dy, dz: event.dz } }));
    }

    setVisible(visible: boolean): void {
        if(visible) {
            this.showPan && (this.panElement.style.display = 'block');
            this.showZoom && (this.zoomElement.style.display = 'block');
        } else {
            this.showPan && (this.panElement.style.display = 'hidden');
            this.showZoom && (this.zoomElement.style.display = 'hidden');
        }
    }
}

class AttributionLayer extends Layer {

    private attibutionElement: HTMLDivElement;

    constructor(root: HTMLDivElement, dimension: Dimension, attribution: string) {
        super(root, dimension, 2);
        this.attibutionElement = document.createElement('div');
        this.attibutionElement.setAttribute('style',`width:${dimension.width - 50}px;heigth:10px;position:absolute;bottom:0;left:3;margin:2px;z-index:${this.zIndex};color:#777;font-size:small;`);
        this.attibutionElement.appendChild(document.createTextNode(attribution));
    }

    setVisible(visible: boolean): void {
        if(visible) {
            this.root.appendChild(this.attibutionElement);
        } else {
            this.root.removeChild(this.attibutionElement);
        }
    }
}

class MarkerLayer extends Layer {

    private markers: Array<{ pin: HTMLImageElement, props: { lat: number, lon: number, text: string }}> = new Array();

    constructor(root: HTMLDivElement, dimension: Dimension) {
        super(root, dimension, 3); // z-index 3
        this.root.addEventListener('map', (e: CustomEvent) => this.render(e));
    }

    private static getPin(color: string, zIndex: string): HTMLImageElement {
        const pin: HTMLImageElement = document.createElement('img');
        pin.width = 32;
        pin.height = 32;
        pin.addEventListener('error', (e) => { (<HTMLImageElement>e.target).src = RedPin });
        pin.setAttribute('style', `position:absolute;z-index:${zIndex};`);
        switch(color) {
            case 'light':
                pin.src = LightPin;
                break;
            case 'blue':
                pin.src = BluePin;
                break;
            case 'green':
                pin.src = GreenPin;
            case 'red':
                pin.src = RedPin;
                break;
            default:
                pin.src = RedPin;
                break;
        }
        pin.alt = '';
        pin.draggable = false;
        return pin;
    }

    public addMarker(marker: { lat: number, lon: number, text: string, color: string }) {
        const pin: HTMLImageElement = MarkerLayer.getPin(marker.color, this.zIndex);
        pin.title = marker.text;
        const index: number = this.markers.findIndex((m) => m.props.text === marker.text);
        if(index < 0) { // add
            this.markers.push({ pin: pin, props: { lat: marker.lat, lon: marker.lon, text: marker.text }});
            this.root.appendChild(pin);
        } else {
            // change latitude, longitude and color attributes
            this.markers[index].props.lat = marker.lat;
            this.markers[index].props.lon = marker.lon;
            this.root.removeChild(this.markers[index].pin);
            this.markers[index].pin = pin;
            this.root.appendChild(pin);
        }
    }

    private render(e: CustomEvent): void {
        const scale: number = 1.0 - (Math.ceil(e.detail.zoom) - e.detail.zoom)/2.0;
        this.markers.forEach(marker => {
            const xy: Coordinate = latlon2xy(marker.props.lat, marker.props.lon, Math.ceil(e.detail.zoom));
            const offset: Coordinate = new Coordinate(e.detail.x - xy.x, e.detail.y - xy.y);
            const ypos: number = this.dimension.height/2.0 - offset.y * TILE_SIZE.height * scale;
            const xpos: number = this.dimension.width/2.0 - offset.x * TILE_SIZE.width * scale;
            marker.pin.style.left = `${xpos}px`;
            marker.pin.style.top = `${ypos}px`; 
        });

    }

    setVisible(visible: boolean): void {
        if(visible) {
            this.markers.forEach(marker => marker.pin.style.display = 'hidden');
        } else {
            this.markers.forEach(marker => marker.pin.style.display = 'block');
        }
    }
}

