

class N<T> {

    key: string;
    value: T;
    next: N<T>;
    prev: N<T>;

    /**
     * 
     * @param key a string key
     * @param value a value (payload) of type T
     * @param next a next node in the linked list, default to null
     * @param prev the previous node in the linked list, defaults to null
     */
    constructor(key: string, value: T, next: N<T> = null, prev: N<T> = null) {
        this.key = key;
        this.value = value;
        this.next = next;
        this.prev = prev;
    }
}

/**
 * https://medium.com/dsinjs/implementing-lru-cache-in-javascript-94ba6755cda9
 */
export class LRU<T> {

    private size: number = 0;
    private limit: number;
    private head: N<T>;
    private tail: N<T> = null;
    private cache: Map<string,N<T>>;

    /**
     * Create the Least Recently Used (LRU) cache with the default limit 10.
     * @param limit 
     */
    constructor(limit: number = 10) {
        this.limit = limit;
        this.cache = new Map();
    }

    public put(key: string, value: T) {
        this.ensureLimit();
        if(!this.head) {
            this.head = this.tail = new N(key, value);
        } else {
            const n = new N(key, value, this.head);
            this.head.prev = n;
            this.head = n;
        }
        this.cache.set(key, this.head);
        this.size++;
    }

    public get(key: string): T|null {
        if(this.cache.get(key)) {
            const value = this.cache.get(key).value;
            this.remove(key);
            this.put(key, value);
            return value;
        }
        return null;
    }

    private ensureLimit(): void {
        if(this.size === this.limit) {
            this.remove(this.tail.key)
        }
    }

    private remove(key: string): void {
        const n = this.cache.get(key);
        if(!n) return;
        if(n.prev) {
            n.prev.next = n.prev;
        } else {
            this.head = n.next;
        }
        if(n.next) {
            n.next.prev = n.prev;
        } else {
            this.tail = n.prev;
        }
        this.cache.delete(key);
        this.size--;
    }

    public clear(): void {
        this.head = null;
        this.tail = null;
        this.size = 0;
        this.cache = new Map();
    }

   public foreach(callback: (node: N<T>, counter: number) => void): void {
        let n: N<T> = this.head;
        let counter: number = 0;
        while(n) {
            callback(n, counter);
            n = n.next;
            counter++;
        }
   }

   /**
    * To iterate over LRU with a 'for...of' loop
    */
   *[Symbol.iterator]() {
       let n: N<T> = this.head;
       while(n) {
           yield n;
           n = n.next;
       }
   }
}