
export class Coordinate {
    x: number = 0;
    y: number = 0;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    /**
     * Create a new coordinate from this shifted by the offset (dx, dy)
     * @param dx 
     * @param dy 
     */
    public translate(dx:number, dy:number): Coordinate {
        return new Coordinate(this.x + dx, this.y + dy);
    }

}


export interface Dimension {
    width: number;
    height: number;
}

/**
 * Number of tiles required for the zoom level.
 * @param zoom a <code>int</code> zoom level.
 * @return a <code>int</code> number of tiles.
 */
function numTiles(zoom: number): number {
    return Math.pow(2.0, zoom);
}

/**
 * Mathematical sec function.
 * @param x <code>double</code> function parameter.
 * @return a <code>double</code> function value.
 */
function sec(x: number): number {
    return (1.0 / Math.cos(x));
}

function toRadians(deg: number): number {
    return deg / 180 * Math.PI;
}

/**
 * 
 * @param lat 
 * @param lon 
 * @param zoom 
 * @param scale use the tile size to get the (x, y) coordinate 
 */
function latlon2xyScaled(lat: number, lon: number, zoom: number, scale: number): Coordinate {
    return new Coordinate(lon2x(lon, zoom) * scale, lat2y(lat, zoom) * scale);
}


function latlon2relativeXY(lat: number, lon: number): Coordinate {
    const latRad: number = toRadians(lat);
    return new Coordinate((lon + 180.0) / 360.0, (1 - Math.log(Math.tan(latRad) + sec(latRad)) / Math.PI) / 2.0)
}

/**
 * Get the (x, y) tiles index for the (lat, lon) coordinate
 * @param lat 
 * @param lon 
 * @param zoom 
 */
export function latlon2xy(lat: number, lon: number, zoom: number): Coordinate {
    return latlon2xyScaled(lat, lon, zoom, 1.0);
}


/**
 * Convert 'slippy maps' tile (x, y) to (lat, lon) in degrees 
 * @param x 
 * @param y 
 * @param zoom 
 */
export function xy2latLon(x: number, y: number, zoom: number): Coordinate {
    let n: number = numTiles(zoom);
    let latRad: number = Math.atan(Math.sinh(Math.PI * (1.0 - 2.0 * y / n)));
    return new Coordinate(latRad * 180.0 / Math.PI, x / n * 360.0 - 180.0);
}

/**
 * Convert longitude (in degrees) to a 'slippy maps' x tile number
 * @param lon 
 * @param zoom 
 */
export function lon2x(lon: number, zoom: number): number {
    return (lon + 180.0) / 360.0 * numTiles(zoom);
}

/**
 * Convert latitude (in degrees) to a 'slippy maps' y tile number
 * @param lat 
 * @param zoom 
 */
export function lat2y(lat: number, zoom: number): number {
    const latRad: number = toRadians(lat);
    return (1.0 - Math.log(Math.tan(latRad) + sec(latRad)) / Math.PI) / 2.0 * numTiles(zoom);
}